import React, { useState } from "react";
import LogoSlider from "react-slick";
import { Row, Modal, Select } from "antd";
import PageContent from "../components/PageContent/PageContent";
import { Banner } from "../components/PfHeplCenter/banner";
import CustomeBreadcrumb from "../components/common/breadcrumb";
import { text } from "../constants";
import { PfData } from "../../services/PfHelpCenterData";
import { HelpCenterData } from "../../services/HelpCenterData";

const { Option } = Select
const PFHelpCenter = props => {
  const slider = {
    dots: false,
    margin: 20,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const pageData = HelpCenterData.PFHelpCenter
  const [visible, setVisible] = useState(false)
  const [value, setValue] = useState([])
  const [id, setId] = useState(null)

  const onclose = () => {
    setVisible(false)
    setId(null)
    setValue([])
  }
  const onOpen = data => {
    setId(data)
    setVisible(true)
  }
  const onChange = e => {
    setValue([e])
  }

  return (
    <>
      <Modal
        title="Select Language"
        visible={visible}
        footer={null}
        maskClosable
        onCancel={() => onclose()}
      >
        <Select
          showSearch
          style={{ width: "100%" }}
          className="categorySort"
          placeholder="Select Language"
          optionFilterProp="children"
          onChange={onChange}
          value={value}
        >
          <Option value="Hindi">Hindi</Option>
          <Option value="English">English</Option>
        </Select>

        {value[0] === "Hindi" && id?.hindi_iframe && (
          <div
            className="my-2"
            dangerouslySetInnerHTML={{ __html: id?.hindi_iframe }}
          />
        )}
        {value[0] === "English" && id?.english_iframe && (
          <div
            className="my-2"
            dangerouslySetInnerHTML={{ __html: id?.english_iframe }}
          />
        )}
      </Modal>
      <Banner title={"PF Help Centre"} />
      <CustomeBreadcrumb
        home_path={"/"}
        home={"Home"}
        current={"PF Help Centre"}
      />
      <PageContent class="" location={props.location}>
        <div className="pf-help-center-section">
          <Row className="text-align-center">
            <h1 className="c-primary">
              <b>{pageData.title}</b>
            </h1>
            <Row
              className="fw-500 font-normal"
              dangerouslySetInnerHTML={{ __html: pageData.description }}
            ></Row>
          </Row>
          <Row className="cardSliders">
            <LogoSlider {...slider}>
              {PfData.map((data, index) => (
                <div className="cardImg">
                  <div key={data.title} className="items">
                    <div className="blackBG">
                      <a key={data.id} onClick={() => onOpen(data)}>
                        <img loading="lazy"  src={data.img} alt={data.title}/>
                      </a>
                    </div>
                    <p>
                      {index + 1}. {data.title}
                    </p>
                  </div>
                </div>
              ))}
            </LogoSlider>
          </Row>
          <Row className="mt-4">
            <h2>{pageData.contents[0].subTitle}</h2>

            <p>
              <ul>
                {pageData.contents[0].contentList?.map((data, index) => (
                  <li key={index}>
                    <p className="p-0 m-0">{data}</p>
                  </li>
                ))}
              </ul>
            </p>
            <p>{pageData.contents[0].footerContent}</p>
          </Row>
          <Row>
            <h2>{pageData.contents[1].subTitle}</h2>
            <div>
              <img loading="lazy" 
                src={pageData.contents[1].image}
                alt="image loading"
                className="w-100 h-100"
                style={{
                  objectFit: "cover",
                  borderWidth: "10px",
                  borderColor: "black",
                }}
              />
            </div>
          </Row>
          <Row justify="center" align="middle" className="mt-4">
            <Row justify="center" align="middle" type="flex">
              <img loading="lazy" 
                src={pageData.attachmentPreviewImage}
                alt="image loading"
                className="w-8 h-8 center ml-3 mt-2"
                style={{
                  objectFit: "cover",
                  borderWidth: "10px",
                  borderColor: "black",
                }}
              />
            </Row>
            <Row justify="center" align="middle" type="flex">
              <a
                download
                href={pageData.attachment}
                className="pt-2 c-primary align-center mt-2"
              >
                <span>{text.CLICK_TO_DOWNLOAD_PF_ATTACHMENT}</span>
              </a>
            </Row>
          </Row>
        </div>
      </PageContent>
    </>
  )
}

export default PFHelpCenter
