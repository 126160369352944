import React, { useEffect, useState } from "react"
import { Row, Col, Breadcrumb, Form, Input, Button, Skeleton } from "antd"
import { Link } from "gatsby"
import backIcon from "../../../images/icons/icons8-back-24.webp"
import PageContent from "../../../components/PageContent/PageContent"
import calendar from "../../../images/calendar.webp"
import wallet from "../../../images/permanent-recruitment-desc.svg"
import experience from "../../../images/experience.svg"
import { staticJobs } from "../../../../services/jobSeekerData"
import location from "../../../images/location.svg"
import { request } from "../../../helper/dimensionHelper"
import { WrappedSubmitYourCvF } from "../../../components/Forms/submit-your-cv-from"

export default function JobSeeker(props) {
  const { ID } = props.params
  const [jobData, setJobData] = useState({
    job: {},
    roles: [],
    about: [],
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getJobDetails(ID)
    const filterData = staticJobs.filter(
      data => data.jobCode.toString() === ID.toString()
    )
    setJobData(filterData[0])
  }, [])

  const getJobDetails = async ID => {
    setLoading(true)
    await request("GET", "JobSite/Jobs/NULL/NULL/NULL/NULL")
      .then(res => {
        if (res?.data) {
          const temjobdata = res.data.filter(
            data => data.jobCode.toString() === ID.toString()
          )
          if (temjobdata && temjobdata[0]) {
            setJobData(prev => ({
              ...prev,
              job: temjobdata[0],
            }))
          }
        }
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
    await request("GET", `JobSite/Job/${ID}`)
      .then(res => {
        console.log("---------11111--->", res.data)
        // const roles = res.data.map(data=>data.description)
        // setJobData(prev=>({
        //     ...prev,
        //     roles
        // }))
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
    setLoading(true)
    await request("GET", `JobSite/JobContentReqm/${ID}`)
      .then(res => {
        console.log(res.data)
        const roles = res.data.map(data => data.description)
        setJobData(prev => ({
          ...prev,
          roles,
        }))
        // setJobData(staticJobs.filter(data=>data.jobCode.toString() === ID.toString()))
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
    setLoading(true)
    await request("GET", `JobSite/JobContent/${ID}`)
      .then(res => {
        const about = res.data.map(data => data.description)
        setJobData(prev => ({
          ...prev,
          about,
        }))
        console.log(res.data)
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
  }
  const [file, setFile] = useState("")

  const fileUpload = event => {
    setFile("")
    let fileName = event.target.files[0]
    setFile(fileName.name)
  }
  console.log("111111111", jobData)
  return (
    <>
      <Row>
        <div className="hero-section">
          <div className="overlay"></div>
          <div className="content-section"></div>
        </div>
      </Row>

      <PageContent class="text-align-justify">
        <Row className="custome-breadcrumb mt-5 pt-5">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/job-seeker"}>
                  <img loading="lazy"  src={backIcon} alt="backIcon"/> Back to Job Seeker
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        {loading ? (
          <>
            <Row className="m-3 m-sm-0">
              <Skeleton
                paragraph={{
                  rows: 12,
                }}
              />
            </Row>
            <Row className="m-3 m-sm-0">
              <Skeleton
                paragraph={{
                  rows: 15,
                }}
              />
            </Row>
          </>
        ) : (
          <Row className="m-3 m-sm-0">
            <Col xs={24} xl={14} lg={14} md={12} sm={24}>
              <Row>
                <Col xs={24} lg={24} md={24} sm={24}>
                  <h3
                    className="js_heading"
                    dangerouslySetInnerHTML={{
                      __html: jobData?.job && jobData?.job?.jobTitle,
                    }}
                  ></h3>
                </Col>
                <Col xs={24} lg={24} md={24} sm={24}>
                  <ul className="timeLocation">
                    <li>
                      <img loading="lazy"  src={calendar} alt="calendar"/>{" "}
                      <span>
                        {jobData?.job && jobData?.job?.jobTypeDescription}
                      </span>
                    </li>
                    <li>
                      <img loading="lazy"  src={location} alt="location"/>{" "}
                      <span>
                        {jobData?.job && jobData?.job?.locationDescription}
                      </span>
                    </li>
                    <li>
                      <img loading="lazy"  src={wallet} alt="wallet"/>{" "}
                      <span>
                        {jobData?.job && jobData?.job?.industryDescription}
                      </span>
                    </li>
                    <li>
                      <img loading="lazy"  src={experience} alt="experience"/>{" "}
                      <span>
                        {jobData?.job && jobData?.job?.expFrom} -{" "}
                        {jobData?.job && jobData?.job?.expTo} Years
                      </span>
                    </li>
                  </ul>
                </Col>
                {/* <p style={{color:'red'}}>{jobData&&jobData[0].description}</p> */}
                {jobData && jobData?.about?.length > 0 && (
                  <Col xs={24} lg={24} md={24} sm={24} className="js_details">
                    <h5>About Position</h5>
                    {jobData &&
                      jobData.about.map(data => (
                        <p
                          key={data}
                          dangerouslySetInnerHTML={{ __html: data }}
                        ></p>
                      ))}
                  </Col>
                )}
                {jobData && jobData?.roles?.length > 0 && (
                  <Col xs={24} lg={24} md={24} sm={24} className="js_details">
                    <h5>Responsibility </h5>
                    <ul>
                      {jobData.roles.map(data => (
                        <li
                          key={data}
                          dangerouslySetInnerHTML={{ __html: data }}
                        ></li>
                      ))}
                    </ul>
                  </Col>
                )}
              </Row>
            </Col>
            <Col
              xs={24}
              xl={8}
              lg={8}
              md={11}
              sm={24}
              className="ml-md-auto float-md-right pos_sticy"
            >
              <div className="js_form">
                <h6 className="js_application">Application</h6>

                <WrappedSubmitYourCvF
                  btnName="Apply Now"
                  jobApply={{
                    type: "JOB_APPLY",
                    data: jobData?.job && jobData?.job?.jobTitle,
                    appliedJobCode: jobData?.job?.jobCode,
                  }}
                />
              </div>
            </Col>
          </Row>
        )}
      </PageContent>
    </>
  )
}
