import axios from "axios"
// const BASE_URL = "https://api.adecco.co.in/"
const BASE_URL = "https://apiuat.adecco.co.in"


const widthPercentageToDP = widthPercent => {
  const screenWidth = window.innerWidth
  // Convert string input to decimal number
  const elemWidth = parseFloat(widthPercent)
  return Math.round(window.devicePixelRatio * ((screenWidth * elemWidth) / 100))
  // return window.devicePixelRatio.roundToNearestPixel(screenWidth * elemWidth / 100);
}
const heightPercentageToDP = heightPercent => {
  const screenHeight = window.innerHeight
  // Convert string input to decimal number
  const elemHeight = parseFloat(heightPercent)
  return Math.round(
    window.devicePixelRatio * ((screenHeight * elemHeight) / 100)
  )
  // return window.devicePixelRatio.roundToNearestPixel(screenHeight * elemHeight / 100);
}
const proportionedPixel = designPixels => {
  const screenPropotion = window.innerWidth / 180
  return Math.round(window.devicePixelRatio * (designPixels * screenPropotion))
  // return window.devicePixelRatio.roundToNearestPixel(designPixels * screenPropotion);
}

const config = {
  baseUrl: BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
}

const ScrollToTop=()=>{
  window.scrollTo({
    top: 100,
    left: 100,
    behavior: 'smooth'
  });
}

const base = async param => {
  // const CancelToken = axios.CancelToken;
  // let source = CancelToken.source();
  // setTimeout(() => {
  //   source.cancel();
  // }, 10000);
  return await axios({
    method: param.method,
    baseURL: config.baseUrl,
    url: param.url,
    // headers: config.headers,
    // cancelToken: source.token,
    data: param.data,
  })
    .then(res => {
      return Promise.resolve(res)
    })
    .catch(err => {
      if (err.response) {
        return Promise.reject(err.response)
      } else {
        return Promise.reject("TIMEOUT")
      }
    })
}

const request = async (method, url) => {
  return await base({ method, url })
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))
}

const requestData = async (method, url, data) => {
  return await base({ method, url, data })
    .then(res => Promise.resolve(res))
    .catch(err => Promise.reject(err))
}

export {
  widthPercentageToDP,
  heightPercentageToDP,
  proportionedPixel,
  request,
  requestData,
  ScrollToTop
}
