import React from "react";
import { Link } from "gatsby";
import { Card, Col, Icon, Row } from "antd";
import Banner from "./employer/case-study/banner";
import { PageContentSubmitCvScreen } from "../components/PageContent/PageContent";
import ImageTextComaponent from "../components/Podcast/imageTextComaponent";
import longRight from "../images/icons/long-right.webp";
import SearchIcon from "../images/icons/search.webp";
import AudioPodcast from "../components/Podcast/audioPodcast";

function Podcast(props) {
  const ServiceSection = ({
    class_name,
    img,
    alt,
    title,
    largeDesc,
    desc,
    link,
    linkTitle,
    target,
  }) => {
    return (
      <div className={class_name}>
        <div>
          <img loading="lazy"  src={img} width={100} alt={alt} />
        </div>
        <h3>{title}</h3>
        <p className="large">{largeDesc}</p>
        <p>{desc}</p>
        <Link to={link} target={target}>
          {linkTitle} <img loading="lazy"  src={longRight} alt={'longRight'}/>
        </Link>
      </div>
    )
  }

  return (
    <PageContentSubmitCvScreen location={props.location}>
      <Banner title={"Podcast"} BannerImg={null} />
      <AudioPodcast/>
      <ImageTextComaponent />
      <section className="msgServiceSection px-3 px-md-6">
        <Row className="d-sm-flex justify-content-center">
          <Col xs={24} sm={12} md={12} lg={8} xl={8}>
            <ServiceSection
              class_name={"jobAlert"}
              img={SearchIcon}
              alt={"Press Release"}
              title={"Press Release"}
              largeDesc={
                "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consectetur, consequuntur?"
              }
              desc={"Lorem ipsum dolor"}
              link={"/employer/press-release/"}
              linkTitle={"Press Release"}
              target={""}
            />
          </Col>
          <Col xs={24} sm={12} md={12} lg={8} xl={8}>
            <ServiceSection
              class_name={"findJob"}
              img={SearchIcon}
              alt={"Case Studies"}
              title={"Case Studies"}
              largeDesc={
                "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Consectetur, consequuntur?"
              }
              desc={"Lorem ipsum dolor"}
              link={"/employer/case-study/"}
              linkTitle={"Case Studies"}
              target={""}
            />
          </Col>
        </Row>
      </section>
    </PageContentSubmitCvScreen>
  )
}
export default Podcast
