import React, { useState } from "react";
import { Link } from "gatsby";
import { Row, Col, Button, Breadcrumb, Collapse } from "antd";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { location } from "../../../services/Constants";
import {
  PageContentEmployerScreen,
  PageContentSubmitCvScreen,
} from "../../components/PageContent/PageContent";
import { Banner } from "../../components/LookingForJobLocation/banner";
import RenderMenu from "../../components/Menu/RenderMenu";
import { contactUs } from "../../../services/EmployerHeaderData";
import BranchData from "../../../services/BranchData";
import locationImage from "../../images/download.png";
import "leaflet/dist/leaflet.css";


const { Panel } = Collapse

const locations = props => {
  const [titleText, setTitleText] = useState("All Branches")
  const [updatedLocationList, setUpdatedLocationList] = useState(
    BranchData.BranchDetails
  )
  const [activeId, setActiveId] = useState("")

  const filterBranchData = (e: any) => {
    let regionId = e.target.getAttribute("id")
    if (regionId === "all") {
      setUpdatedLocationList(BranchData.BranchDetails)
    } else {
      const data = BranchData.BranchDetails.filter(data => data.key === location[regionId])
      setUpdatedLocationList(data)
      setTitleText(location[regionId])
      setActiveId(regionId)
    }
  }


  return (
    <PageContentEmployerScreen location={props.location} class="py-5">
      <div className="bg-white pb-9">
        <Banner />
        <Row className="custome-breadcrumb  p-3">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item overlay={<RenderMenu data={contactUs} />}>
                Contact Us
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a href="">Locations</a>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <Row className="ant-row mx-3 mx-lg-9 mx-xl-9 map-location">
          <MapContainer
            center={[21.7679, 78.8718]}
            zoom={4.5}
            style={{ height: "600px" }}
            // scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {updatedLocationList
              ?.sort((a: any, b: any) => {
                if (a.title < b.title) return -1
                if (a.title > b.title) return 1
                return 0
              })
              ?.map((data, index) => (
                <Marker position={data.lat_long} key={data.title}>
                  <Popup>
                    {data.title} <br />
                    {data.Address}
                    <br />
                    {data.TelNo}
                  </Popup>
                </Marker>
              ))}
          </MapContainer>
        </Row>

        <Row
          type="flex"
          align="middle"
          justify="center"
          className="px-md-8 px-0  w-100"
        >
          <Row type="flex" justify="center" className="w-80">
          
            <Row
              type="flex"
              justify="space-between"
              className=" px-5 px-lg-0 my-md-6 my-4 w-100"
            >
              <Col className="pb-1 w-100 w-md-auto">
                <Button
                  onClick={e => filterBranchData(e)}
                  size="large"
                  id="all"
                  className={`c-black w-100 ${
                    activeId === "all" ? "active" : ""
                  }`}
                >
                  {location.all}
                </Button>
              </Col>
              <Col className="pb-1 w-100 w-md-auto">
                <Button
                  onClick={e => filterBranchData(e)}
                  size="large"
                  id="north"
                  className={`c-black w-100 ${
                    activeId === "north" ? "active" : ""
                  }`}
                >
                  {location.north}
                </Button>
              </Col>
              <Col className="pb-1 w-100 w-md-auto">
                <Button
                  onClick={e => filterBranchData(e)}
                  size="large"
                  id="south"
                  className={`c-black w-100 ${
                    activeId === "south" ? "active" : ""
                  }`}
                >
                  {location.south}
                </Button>
              </Col>
              <Col className="pb-1 w-100 w-md-auto">
                <Button
                  onClick={e => filterBranchData(e)}
                  size="large"
                  id="east"
                  className={`c-black w-100 ${
                    activeId === "east" ? "active" : ""
                  }`}
                >
                  {location.east}
                </Button>
              </Col>
              <Col className="pb-1 w-100 w-md-auto">
                <Button
                  onClick={e => filterBranchData(e)}
                  size="large"
                  id="west"
                  className={`c-black w-100 ${
                    activeId === "west" ? "active" : ""
                  }`}
                >
                  {location.west}
                </Button>
              </Col>
            </Row>
          </Row>
        </Row>
        <Row
          type="flex"
          className="justify-content-md-start justify-content-center"
        ></Row>

        <h1 className="fw-600 font-h5 px-2 mx-7 px-md-7 pt-2">{titleText}</h1>
        <Row className="px-2 mx-7 px-md-7">
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Collapse
              expandIconPosition="right"
              accordion
              className="locationArcodin"
            >
              {updatedLocationList
                ?.sort((a: any, b: any) => {
                  if (a.title < b.title) return -1
                  if (a.title > b.title) return 1
                  return 0
                })
                ?.map((data, index) => {
                  return (
                    <Panel
                      header={
                        <>
                          <img loading="lazy"  src={locationImage} className="pr-2 h-4" alt="locationImage"/>
                          {data.title}
                        </>
                      }
                      key={index}
                    >
                      <div className="pb-1 c-secondary font-normal">
                        Adecco India
                      </div>
                      <p className="c-secondary font-normal">{data.Address}</p>
                      <div>
                        <span>
                        </span>
                        {data.TelNo && (
                          <span className="c-secondary font-normal">
                            {" "}
                            Tel No: {data.TelNo}
                          </span>
                        )}
                      </div>
                    </Panel>
                  )
                })}
            </Collapse>
          </Col>
        </Row>
        
      </div>
      <Row className="align-items-center p-6  pt-5">
        <div className="py-2">
          <p className=" lead px-md-6 ">
          Adecco is a leading global staffing solutions provider with a strong presence in India. We have several locations throughout the country, offering diverse services such as permanent staffing, temporary staffing, recruitment process outsourcing, and payroll management. Each location is strategically chosen to cater to specific regional needs and industries, ensuring that Adecco is well-equipped to provide tailored solutions to its clients.
          </p>
          <p className=" lead px-md-6 m-0">
          Adecco's locations offer a range of staffing solutions for businesses, including talent acquisition, workforce management, and HR consulting. With a focus on innovation and technology, We,at Adecco provide cutting-edge solutions that help businesses streamline their hiring and staffing processes. Whether you need to hire temporary or permanent staff, manage payroll, or outsource your recruitment process, Adecco has the expertise and resources to help you achieve your goals.
            </p>
          </div>
          <div className="py-4 px-6 d-flex justify-content-around">
          <Button type="danger" size="large" className="mr-1">
          <Link className="btn" to="/submit-your-cv/">Looking for a job?</Link>
              </Button>
              <Button type="danger" size="large" className="ml-1">
              <Link to="/employer/business-enquiry/">Are you an employer?</Link>
              </Button>
            
            
          </div>
        </Row>
    </PageContentEmployerScreen>
  )
}

export default locations
