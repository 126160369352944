import React from "react";
import { Col, Row } from "antd";
import ourValueData from "../../../services/ourValue";

export const OurValuesCard = () => {
  return (
    <section className="overvalueCardSection px-3 pb-5 px-md-6">
      <Row
        type="flex"
        justify="center"
        className="font-h6 fw-600  pt-3 pb-4 text-align-center"
      >
        <h1>At Adecco we are guided by our values for all our stakeholders</h1>
      </Row>
      <Row gutter={[16, 24]}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="valcardGrid">
            {ourValueData &&
              ourValueData?.map(data => {
                return (
                  <div className="value-card">
                    <Row type="flex" justify="center" className="pb-2">
                      <img loading="lazy"  src={data.img} alt="image coming soon" width={200} />
                    </Row>
                    <Row
                      type="flex"
                      justify="center"
                      className="text-align-center pb-2 font-h5"
                      style={data.sub?{display:'grid'}:{}}
                    >
                      <b style={{color:"#ef2e24"}}>{data.title}</b>
                      
                      {data.sub?(<><br/><small style={{color:"#ef2e24"}}>{data.sub}</small></>):''}
                    </Row>
                    <Row
                      type="flex"
                      justify="center"
                      className="text-align-center px-4 lead"
                    >
                      {data.content}
                    </Row>
                  </div>
                )
              })}
          </div>
        </Col>
      </Row>
    </section>
  )
}
