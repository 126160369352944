import React, { useEffect } from "react"
import { Col, Row } from "antd"
import LogoSlider from "react-slick"
import { benefitsData } from "../../../services/jobSekeerHomePageData"
import axios from "axios"

const slider = {
  dots: false,
  arrows: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 1000,
  autoplaySpeed: 4000,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}


const Benefits = () => {
  useEffect(()=>{
    getLinkedInPosts()
  },[])
  
  function getLinkedInPosts() {
    const clientId = '77k03eo79vc3i9';
    const clientSecret = 't8pWFe3nS4iy4EZM';
  
    const accessTokenUrl = `https://api.linkedin.com/oauth/v2/accessToken?grant_type=client_credentials&client_id=${clientId}&client_secret=${clientSecret}`;
  
    axios
      .get(accessTokenUrl)
      .then((response) => {
        console.log({response})
        const accessToken = response.data.access_token;
        const postsUrl = 'https://api.linkedin.com/v2/ugcPosts?q=authors';
        
        axios
          .get(postsUrl, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then((response) => {
            const posts = response.data.elements;
            console.log(posts); // Do something with the posts data
          })
          .catch((error) => {
            console.error('Error retrieving LinkedIn posts:', error);
          });
      })
      .catch((error) => {
        console.error('Error retrieving LinkedIn access token:', error);
      });
  }
  return (
    <Row className="benefitsSection px-3 px-md-6">
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <div className="heading">
          <h2>{benefitsData?.title}</h2>
        </div>
      </Col>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <div className="benefitData">
          <LogoSlider {...slider}>
            {benefitsData?.data?.map((data, index) => (
              <div key={data.title} className="items benifitsCard">
                <img loading="lazy"  src={data.image} alt={data.title}/>
                <h5>{data.title}</h5>
                <p>{data.desc}</p>
              </div>
            ))}
          </LogoSlider>
        </div>
      </Col>
    </Row>
  )
}

export default Benefits
