import React from "react";
import { Card } from "antd";
import OurTeam from "../../components/AboutUs/our-team";
import {
  PageContentEmployerScreen,
} from "../../components/PageContent/PageContent";
import Banner from "../../components/AboutUs/banner";
import Activity from "../../components/AboutUs/activity";
import ProgramsAndPartnership from "../../components/AboutUs/programs-and-partnerships";
import JobSekeerAndEmployer from "../../components/AboutUs/job-sekeer-and-employer";
import NewsRooms from "../../components/AboutUs/newsrooms";
import ReportSections from "../../components/AboutUs/reportSections";
import { activityData } from "../../../services/aboutUsData";
import AwardsAndRecognition from "../../components/AboutUs/awardsAndRecognition";

const { Meta } = Card

const AboutUs = props => {
  return (
    <>
      <PageContentEmployerScreen location={props.location} pageSize={24}>
        <Banner
          heading={"We’ve got the world of work covered"}
          desc={
            "Our workforce solutions offering is unmatched in its global scale, local knowledge, and innovative use of digital tools. We place more than 600,000 associates worldwide into roles daily, enabling ﬂexibility and agility for our clients. As a career partner and employer of choice, we support inclusiveness and employability of our associates and are committed to their continued success. We stand out in our commitment to operating responsibly, in our belief in the potential of people, and by always being there when needed."
          }
        />
        <OurTeam />
        <Activity data={activityData} css={"px-3 px-md-6"} />
        <AwardsAndRecognition/>
        <ProgramsAndPartnership />
        <br />
        <NewsRooms />
        <ReportSections />
        <JobSekeerAndEmployer />
      </PageContentEmployerScreen>
    </>
  )
}

export default AboutUs
