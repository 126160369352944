import React, { useRef, useState } from "react";
import { Row, Modal,  Col,  } from "antd";
import {PageContentSubmitCvScreen} from "../components/PageContent/PageContent";
import  Banner from "../components/SalaryGuid/banner";
import CustomeBreadcrumb from "../components/common/breadcrumb";
import { HelpCenterData } from "../../services/HelpCenterData";
import { WrappedMediaEnquiryForm } from "../components/Forms/MediaInquiryFrom";
import salaryImage from "../images/salary-guid/image2-Salary-guide.webp";
import './style.less';

const SalaryGuide = props => {
  const [isDownload, isSetDownload]:any=useState(false)
  const link:any = useRef()
  
  
  const pageData = HelpCenterData.PFHelpCenter
  const [visible, setVisible] = useState(false)
  const [value, setValue] = useState([])
  const [id, setId] = useState(null)

  const onclose = () => {
    setVisible(false)
    
  }
  const onOpen = () => {
    setVisible(true)
  }
  const handleDownload=(val)=>{
    setVisible(false);
    console.log("00000000000",link)
    link.current.click()
  }
  console.log({visible})
  return (
    <>
      <Modal
        title="Select Language"
        visible={visible}
        footer={null}
        maskClosable
        onCancel={() => onclose()}
      >
        <WrappedMediaEnquiryForm span1={5} span2={19} isSetDownload={(val)=>handleDownload(val)} />
      </Modal>
      {/* <Banner title={"Salary Guide"} /> */}
      <Banner onOpen={onOpen}/>
      <CustomeBreadcrumb
        home_path={"/"}
        home={"Home"}
        current={"Salary Guide"}
      />
      <PageContentSubmitCvScreen class="" location={props.location}>
        <div className="salary-guid-section">
          <Row className="font-normal">
              <h1 className="">Navigate the Evolving Indian Job Market with the 2024 Salary Guide</h1>
              <p>2023 presented unique challenges for Indian employers: Global economic shifts, evolving talent expectations, and a persistent shortage of leadership professionals. In this dynamic landscape, both employers and job seekers need data-driven insights to make informed decisions. Our 2024 India Salary Guide equips you with just that. Get your hands on current salary benchmarks, industry trends, and expert insights specifically tailored to the Indian market. Whether you're an experienced HR professional navigating talent acquisition, or a candidate aiming to secure the best opportunity, this guide empowers you to go beyond simply salary figures.</p>
              <p>Uncover realistic salary expectations, understand workplace dynamics, and make strategic career moves aligned with your goals. Download the 2024 India Salary Guide today and gain a competitive edge!</p>
            </Row>
          <Row className="cardSliders">
          </Row>
          <Row className="mt-4">
            <h2>Highlights</h2>
            <p>
              <ul>
                  <li>
                    <p className="p-0 m-0">Gain valuable salary insights from over 600 responses across 11 key industries.</p>
                  </li>
                  <li>
                    <p className="p-0 m-0">Exclusive insights on India and APAC labor market from Adecco’s executives.</p>
                  </li>
                  <li>
                    <p className="p-0 m-0">Explore the importance of new leadership skills, AI's impact on talent spending, essential skills for job seekers, and insights into workplace culture and talent retention in India.</p>
                  </li>
              </ul>
            </p>
          </Row>
          <Row>
            <div className="mb-2">
              <p className="p-0 m-0">Get the latest salary insights of all the major positions.</p>
            </div>
            <Col md={16} className="mb-2">
              <img className="w-100" src={salaryImage} alt="salary-image"/>
            </Col>
          </Row>
          <div className="d-inline-block">
            {/* <a
                download
                href={pageData.attachment}
                id={"isDownload"}
                // ref={link}
                className="pt-2 c-primary align-center mt-2"
              >
                <span>Click Here To Download Adecco Salary Guide</span>
              </a> */}
              <a download id={"isDownload"}  className="pt-3 c-primary align-center mt-3">
                <span onClick={onOpen}>Download the salary guide </span>
              </a>
            </div>
          <Row className="mt-3">
            <h2>Disclaimer:</h2>
            <p>The Adecco India Salary Guide 2024 ("Guide") is provided as a courtesy and for informational purposes only. It is not intended to be, and should not be construed as, legal, or financial advice. While Adecco India has used commercially reasonable efforts to collect and compile the data presented in the Guide, the information provided is based on various sources and is intended for
general guidance only. It does not constitute an exhaustive or definitive statement of market trends or individual salary expectations.</p>
              <p>Adecco India expressly disclaims any and all warranties, express or implied, regarding the accuracy, completeness, reliability, or timeliness of the information contained in the Guide. Users of the Guide acknowledge and agree that their use of the information provided is at their own risk and that Adecco India shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from such use.</p>
              <p>The Guide is the exclusive property of Adecco India and is protected by copyright and other intellectual property laws. No part of the Guide may be reproduced, transmitted, or distributed in any form or by any means without the prior written permission of Adecco India.</p>
          </Row>
          {/* <Row>
            <h2>{pageData.contents[1].subTitle}</h2>
            <div>
              <img loading="lazy" 
                src={pageData.contents[1].image}
                alt="image loading"
                className="w-100 h-100"
                style={{
                  objectFit: "cover",
                  borderWidth: "10px",
                  borderColor: "black",
                }}
              />
            </div>
          </Row> */}
          {/* <Row className="text-center">
            <Button type="danger" size="large" onClick={()=>setVisible(true)}>Click Here To Download Adecco Salary Guide</Button>
          </Row> */}
          {/* <Row>
          <Col lg={12} xl={12} xxl={12}  md={24} className="d-lg-block d-none text-center">
            <img loading="lazy" className="mt-lg-2" style={{width:"98%"}}  src={pageData.attachmentPreviewImage} alt="pageData.attachmentPreviewImage" />
            {isDownload &&<Row justify="center" align="middle" type="flex">
              <a
                download
                href={pageData.attachment}
                id={"isDownload"}
                ref={link}
                className="pt-2 c-primary align-center mt-2 "
              >
                <span>Click Here To Download Adecco Salary Guide</span>
              </a>
            </Row>}
           
          </Col>
          <Col lg={12} xl={12} xxl={12} md={24} xs={22}>
            <WrappedMediaEnquiryForm span1={4} span2={24} isSalary={true} isSetDownload={(val)=>isSetDownload(val)}/>
          </Col>
          </Row> */}
          <a
                download
                href={pageData.attachment}
                id={"isDownload"}
                ref={link}
                className="pt-2 c-primary align-center mt-2 d-none"
              >
                <span>Click Here To Download Adecco Salary Guide</span>
              </a>
          {/* <Row justify="center" align="middle" className="mt-4">
            <Row justify="center" align="middle" type="flex">
              <img loading="lazy" 
                src={pageData.attachmentPreviewImage}
                alt="image loading"
                className="w-5 h-10 center ml-3 mt-2"
                style={{
                  objectFit: "cover",
                  borderWidth: "10px",
                  borderColor: "black",
                }}
              />
            </Row>
            <Row justify="center" align="middle" type="flex">
              <a
                download
                href={pageData.attachment}
                className="pt-2 c-primary align-center mt-2"
              >
                <span>Click Here To Download Adecco Salary Guide</span>
              </a>
            </Row>
          </Row> */}
        </div>
      </PageContentSubmitCvScreen>
    </>
  )
}

export default SalaryGuide
