import fileIcon from "../src/images/icon/paper.webp"
import folderIcon from "../src/images/icon/icons8-folder.webp"

export const complianceData = {
  title: "COMPLIANCE",
  description:
    "Any questions so far? We'd be happy to help. Check out the answers to some of the questions <br/> we get most often.",
  icons: [
    { name: "file_icon", path: fileIcon },
    { name: "folder_icon", path: folderIcon },
  ],
  documents: [
    {
      title: `ADE_ST-3362-TATA AUG'21.RAR`,
      path: `../../Document/ADE_ST-3362-TATA AUG'21.RAR`,
    },
    {
      title: `ADE_ST-3362-TATA SEP'21.RAR`,
      path: `../../Document/ADE_ST-3362-TATA SEP'21.RAR`,
    },
    {
      title: `ADE_ST-3362-TATA OCT'21.RAR`,
      path: `../../Document/ADE_ST-3362-TATA OCT'21.RAR`,
    },
    {
      title: `ADE_ST-3362-TATA Nov'21.RAR`,
      path: `../../Document/ADE_ST-3362-TATA Nov'21.RAR`,
    },
    {
      title: `ADE_ST-3362-TATA DEC'21.RAR`,
      path: `../../Document/ADE_ST-3362-TATA DEC'21.RAR`,
    },
    {
      title: `PF Challans & ECRs.zip`,
      path: `../../Document/PF Challans & ECRs.zip`,
    },
  ],
  folder: [
    {
      title: `TCS`,
      path: `https://inoffice-my.sharepoint.com/:f:/g/personal/benos_mpr_apac_adecco_net/ElSzh8vJPnNKkSrlLKcMMbABYyIzcZrxVC4OehQvu5O08w?e=kXqbpG`,
    },
    {
      title: `Metropolis`,
      path: `https://inoffice-my.sharepoint.com/:f:/g/personal/benos_mpr_apac_adecco_net/EmuhZgJDVYREg869uiwyNWQBxpA2ruIARWi432X9dxnXKg?e=8p0EWj`,
    },
    {
      title: `Max Reach Swiggy`,
      path: `https://inoffice-my.sharepoint.com/:f:/g/personal/benos_mpr_apac_adecco_net/EqaoRl9iHr9JrHcheCs_jKoBm0ETGaTY65n4hbska5wi6g?e=OZDUzn&CT=1651752972905&OR=OWA-NT&CID=ab19e6df-3ccc-5844-20cc-4a4d1391bd0f`,
    },
    {
      title: `Yakult`,
      path: `https://inoffice-my.sharepoint.com/:f:/g/personal/benos_mpr_apac_adecco_net/EticHp5xSvdLsIsXZnbIbwUBe7Q-R1KLvZUGxcejhyQX7w?e=5%3aA4V0MY&at=9&CT=1654149341486&OR=OWA-NT&CID=ac673a18-4717-72e8-a82d-d9184d98538b`,
    },
    {
      title: `Max Reach`,
      path: `https://inoffice-my.sharepoint.com/:f:/g/personal/vijaya_krishna_apac_adecco_net/EmzHZBITVaRJrMX3PXtl70EB8XZytKpQDcA_1EPG7Oko8Q?email=Rakesh.Akki%40adecco.com&e=wFJzlg`,
    },

    {
      title: `PYD`,
      path: `https://inoffice-my.sharepoint.com/:f:/g/personal/vijaya_krishna_apac_adecco_net/EqUxWLYlI3lApFWiZTbAD4IBu5oDk_dQss2DkYkDj6EoFw?email=Rakesh.Akki%40adecco.com&e=kqkh7X`,
    },
    {
      title: `ROJ`,
      path: `https://inoffice-my.sharepoint.com/:f:/g/personal/vijaya_krishna_apac_adecco_net/EikrgyFBOAtKh-A8VO_oInEBJQe2BKHStl5mDiQzUwIBdA?email=Rakesh.Akki%40adecco.com&e=O09ilR`,
    },
    {
      title: `Ariga`,
      path: `https://inoffice-my.sharepoint.com/:f:/g/personal/vijaya_krishna_apac_adecco_net/EoImjsohdURCpqQiAWGbeLgBvxlK7CVngMSIrMsd7ZfJUg?email=Rakesh.Akki%40adecco.com&e=hWkapz`,
    },
    {
      title: `Scootsy Logistics`,
      path: `https://inoffice-my.sharepoint.com/:f:/g/personal/vijaya_krishna_apac_adecco_net/EpiMUbHNpZlFg9K_P-8yShkB2MbByEaB8OGi3ZER70l9cg?email=Rakesh.Akki%40adecco.com&e=Rfxkbf`,
    },
    {
      title: `DHL`,
      path: `https://inoffice-my.sharepoint.com/:f:/g/personal/vijaya_krishna_apac_adecco_net/Esl_ZYj9lGZDrneRYtb-gjUBh03rnvrTUCo0jhHGZQ-cUA?email=Rakesh.Akki%40adecco.com&e=OiQT57`,
    },
  ],
}
