import React from 'react';
import { Button, Col, Row } from 'antd';
import banerImage from '../../images/salary-guid/banner-image-Salary-guide.webp'
import "./styles.less"


const Banner = ({onOpen}) => {
  return (
    <div className='salary-guide-banner'>
        <Row className='salary-guide-banner-row'>
            <Col lg={12}>
                <div className='salary-guide-banner-image'>
                    <img src={banerImage} alt="banner-image" />
                </div>
            </Col>
            <Col lg={12}>
                <div className='salary-guide-banner-text'>
                <p className='text'>Stay Ahead of the Curve! Access meticulously curated 2024 salary trends spanning a wide spectrum of pivotal factors pertinent to both hiring managers and prospective employees. This comprehensive analysis aims to offer data-driven insights into the dynamic landscape of Human Resources practices in India, serving as an indispensable tool for charting a course toward the future.</p>
                <div className="text-center salary-guide-banner-btn">
                <Button type="danger" onClick={()=>onOpen(0)}>Download the salary guide</Button>
                </div>
                </div>

            </Col>
        </Row>
    </div>
  )
}

export default Banner