import React from 'react'
import { Card, Col, Icon, Row } from 'antd'
import "./style.less";

const AudioPodcast = () => {
  return (
    <section className="audio-podcast p-3 px-md-6">
        <div className='podcast-title'>
            <h1>Podcasts</h1>
        </div>
        <div>
          <Row gutter={[24, 24]}>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className='audio-card'
                actions={[
                  <><Icon type="calendar" key="calendar" />5 Sep 23</>,
                  <><Icon type="bars" key="bars" />Episode 17</>,
                  <><Icon type="clock-circle" key="clock-circle" /> 24:11</>,
                ]}
                cover={ <div className='media-container'>
                    <img className='card-media' alt="example" src="https://pbcdn1.podbean.com/imglogo/image-logo/14911725/Podcast_Designs_3000_3000px_1__gys22w.jpg"/>
                    </div>}
              >
                <div>
                  <div className="w-100">
                    <audio className="plyr-player" id="plyr-player-4" controls>
                      <source src="https://mcdn.podbean.com/mf/web/a7q4ia/Ep17_Sophie_Allen9jqgr.mp3" type="audio/mp3"/>
                    </audio>
                  </div>
                  <div className='mt-2'>
                  <h5 className="episode-title w-100 text-dark line-clamp">Ep17: 30 Minutes with Sophie Allen - The value of personal branding and relationships to help become a successful recruiter</h5>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className='audio-card'
                actions={[
                  <><Icon type="calendar" key="calendar" />5 Sep 23</>,
                  <><Icon type="bars" key="bars" />Episode 17</>,
                  <><Icon type="clock-circle" key="clock-circle" /> 24:11</>,
                ]}
                cover={ <div className='media-container'>
                    <img className='card-media' alt="example" src="https://pbcdn1.podbean.com/imglogo/image-logo/14911725/Podcast_Designs_3000_3000px_1__gys22w.jpg"/>
                    </div>}

              >
                <div>
                  <div className="w-100">
                    <audio className="plyr-player" id="plyr-player-4" controls>
                      <source src="https://mcdn.podbean.com/mf/web/a7q4ia/Ep17_Sophie_Allen9jqgr.mp3" type="audio/mp3"/>
                    </audio>
                  </div>
                  <div className='mt-2'>
                  <h5 className="episode-title w-100 text-dark line-clamp">Ep17: 30 Minutes with Sophie Allen - The value of personal branding and relationships to help become a successful recruiter</h5>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8} xl={8}>
              <Card className='audio-card'
                actions={[
                  <><Icon type="calendar" key="calendar" />5 Sep 23</>,
                  <><Icon type="bars" key="bars" />Episode 17</>,
                ]}
                cover={
                    <div className='media-container'>
                    <iframe className='card-media'  src="https://www.youtube.com/embed/EKcHBzfuVfI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                }

              >
                <div>
                  <div className='mt-2'>
                  <h5 className="episode-title w-100 text-dark line-clamp-1">Ep17: 30 Minutes with Sophie Allen - The value of personal branding and relationships to help become a successful recruiter</h5>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </section>
  )
}

export default AudioPodcast