import React from "react";
import { Row, Card, Col, Button } from "antd";
import {
  PageContentEmployerScreen,
} from "../../components/PageContent/PageContent";
import AwardData from "../../../services/AwardRecognition";
import { Banner } from "../../components/AwardRecognition/banner";
import { aboutUs } from "../../../services/EmployerHeaderData";
import { BredcrumbEmployer } from "../../components/common/bredcrumbEmployer";

const AwardRecognition = props => {
  const showAwards = (start: number, end: number) => {
    return (
      <Col lg={12} md={24} className="p-md-2 text-align-center">
        {AwardData &&
          AwardData.slice(start, end).map(data => {
            return (
              <Card
                className="m-3"
                style={{
                  borderRadius: "0.625rem",
                  boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.16)",
                }}
                key={AwardData.indexOf(data)}
              >
                <Row type="flex" align="middle" justify="center">
                  {/* <Col lg={8} md={24} className="p-md-2 text-align-center"> */}
                  <div className="mb-4">
                    <img loading="lazy"  src={data.img} className="mw-80" alt="img"/>
                  </div>
                  {/* </Col> */}
                  {/* <Col lg={16} md={24}> */}
                  <div>
                  <h2 className="font-large fw-600 pb-1">{data.title}</h2>
                    <p
                      className=""
                      style={{ lineHeight: "1.6", opacity: "0.8" }}
                    >
                      {data.content}
                    </p>
                  </div>
                   
                  {/* </Col> */}
                </Row>
              </Card>
            )
          })}
      </Col>
    )
  }

  const showMoreAwards = () => {
    const showAwards = document.getElementById("showMore")
    if (showAwards && showAwards.style !== null)
      showAwards.style.display = "block"
  }

  return (
    <PageContentEmployerScreen class=" " location={props.location}>
      <Banner />
      <BredcrumbEmployer home={'Home'} home_path={"/employer"}data={aboutUs}  perent={'About Us'} current={'Awards & Recognition'} />
      <div className="award-section">
        <h1 className="heading-3 pt-5 text-align-center">
          Awards & Recognition{" "}
        </h1>
        <Row
          className="font-large px-md-9 px-sm-5 px-4 "
          style={{ lineHeight: "1.7" }}
          type="flex"
          justify="center"
        >
          <p>
            When you put everything you’ve got into being a good employer,
            finding the perfect someone for that perfect job, and giving young
            graduates and disadvantaged job seekers a leg up on the job ladder,
            people can’t help but notice. We’re very thankful for all the
            honours we’ve received over the years.
          </p>
        </Row>
        <Row className="pt-2 px-md-8 px-2" type="flex" justify="center">
          {showAwards(0, 5)}


        </Row>
        <div
          className="pt-2 px-md-8 px-2"
          style={{ display: "none" }}
          id="showMore"
        >
          {showAwards(5, AwardData.length)}
        </div>
        {/* <Row type="flex" justify="center" className="d-none">
          <Button className=" m-5 btn-white" onClick={() => showMoreAwards()}>
            View More
          </Button>
        </Row> */}
      </div>
    </PageContentEmployerScreen>
  )
}

export default AwardRecognition
