import React from "react";
import { Link } from "gatsby";
import { Row, Col, Breadcrumb } from "antd";
import { WrappedMediaEnquiryForm } from "../../components/Forms/MediaInquiryFrom";
import RenderMenu from "../../components/Menu/RenderMenu";
import { media } from "../../../services/HeaderData";
import { PageContentEmployerScreen } from "../../components/PageContent/PageContent";
import { BannerMedaiaEn } from "../../components/BusinessEnquiry/bannerMediaEn";
import mediaEnquiryImage from "../../images/PressRelease/Media-Coverage.webp";

const MediaInquiry = props => {
  return (
    <PageContentEmployerScreen location={props.location}>
      <BannerMedaiaEn />
      <div className="mediaInquiry-section">
        <Row className="custome-breadcrumb mt-5 pt-5">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/employer"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item overlay={<RenderMenu data={media} />}>
                News
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a href="">Media Enquiry</a>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>

        <Row className="align-items-center px-6 px-md-0 pt-5">
          <div className="text-align-center lead">
            If your enquiry is in relation to accessing, correcting or deleting
            any personal information we may have in our directories, please
            visit our our privacy management platform{" "}
            <Link to={"/privacy-policy/"}> here.</Link>
          </div>
        </Row>
        <Row type="flex" justify="space-around" className=" mt-5 mb-5">
          <Col lg={10} md={24} className="d-lg-block d-none text-center">
            <img loading="lazy"  src={mediaEnquiryImage} alt="mediaEnquiryImage" />
          </Col>
          <Col lg={12} md={24} xs={22}>
            <WrappedMediaEnquiryForm span1={4} span2={20} />
          </Col>
        </Row>
        <div className="text-align-center mb-5">
          <p>
            <b>For further information please contact Cauvery Uthappa,</b>
          </p>{" "}
          <p>
            {" "}
            Associate Director - Marketing & Communications <br />{" "}
            <a href="mailto:cauvery.uthappa@adecco.com" className="taxt-dark">
              cauvery.uthappa@adecco.com
            </a>
          </p>
        </div>
        
      </div>
     
    </PageContentEmployerScreen>
  )
}

export default MediaInquiry
