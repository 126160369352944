import React, { useState } from "react";
import { Row, Col } from "antd";
import CustomeBreadcrumb from "../../components/common/breadcrumb";
import { PageContentEmployerScreen } from "../../components/PageContent/PageContent";
import { Banner } from "../../components/BusinessEnquiry/banner";
import { WrappedBusinessEnquiryF } from "../../components/Forms/business-enquiry-from";
import { text } from "../../constants";
import businessEnquiryImage from "../../images/Business-enq1.webp";

export interface IFormProps {
  location: any
  pageSource: string
}

const BusinessEnquiryForm = (props: IFormProps) => {
  const interested = props.location.state ? props.location.state.interested : ""
  const [interestedInData, setInterestedInData] = useState(
    interested === "" ? "Contact us about working together" : interested
  )

  const changeInterestedArea = (value: string) => {
    setInterestedInData(value)
  }

  const enquiryFrom = () => {
    return (
      <div className="mt-6">
        <Row type="flex" justify="space-around" className="px-4 px-md-0">
          <Col lg={10} md={24} className="d-lg-block d-none">
            <Row className="pos-relative text-align-center d-inline-block align-items-center justify-content-center d-flex">
              <img loading="lazy"  src={businessEnquiryImage} className="mw-90" alt="businessEnquiryImage"/>
              
            </Row>
          </Col>
          <Col lg={10} md={24} xs={22}>
            <WrappedBusinessEnquiryF
              getInterestedArea={changeInterestedArea}
              showInterestedInValue={interested}
              pageSource={props.pageSource}
            />
          </Col>
        </Row>
      </div>
    )
  }
  return props.pageSource === text.DIGITAL_BUSINESS ? (
    <div className="px-7 py-3">{enquiryFrom()}</div>
  ) : (
    <PageContentEmployerScreen location={props.location} class="py-5">
      <Banner />
      <CustomeBreadcrumb home={'Home'} home_path={'/employer'} current={'Business Enquiry'} />
     
      {enquiryFrom()}
      <Row className="align-items-center p-6  bg-white">
        <div className="py-2">
          <p className=" lead px-md-6 ">
          Adecco is a leading global HR solutions provider, offering comprehensive staffing and workforce solutions for businesses of all sizes across various industries. We specialize in temporary staffing, permanent placement, payroll outsourcing, international engagement, training and skilling services to help organizations achieve their workforce goals.
          </p>
          <p className=" lead px-md-6 m-0">
          Ready to find the right talent for your business? Contact us today and let us help you build your dream team. Submit your enquiry now and one of our experts will get in touch with you to discuss your staffing needs. Trust Adecco to help you find the right fit for your organization.
            </p>
          </div>
        </Row>
    </PageContentEmployerScreen>
  )
}

export default BusinessEnquiryForm
