import React from "react"
import {
  Form,
  Input,
  Button,
  Checkbox,
  Select,
  ConfigProvider,
  message,
  Row,
  Col,
  DatePicker,
} from "antd"
import Axios from "axios";
import {
  characterValidation,
  countryCodeData,
  numberVal,
  phoneNumberValidation,
  validateFileSize,
  validateFileType,
} from "./FormUtils"
import { request, requestData } from "../../helper/dimensionHelper";
import ModalComponent from "../ModalComponent/Modal"
import { TermsConditionData } from "../../../services/termsAndConditionData"
import uuid from "react-uuid"
import BranchData from "../../../services/BranchData"
import { DivisionData } from "../../../services/veternanSubmissionData"
import { APIContext } from "../../helper/APIContex"

const { Option } = Select

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field])
}

function VeteranSubmissionForm(props) {
  const {
    getFieldDecorator,
    getFieldsError,
    getFieldError,
    isFieldTouched,
  } = props.form
  console.log(props.form.getFieldsValue())
  const { data, actions } = React.useContext(APIContext)
  const { location,language,division } = data.veteranData;
  const {getVeteranData}=actions;
  const [dob, setDob] = React.useState({})
  const [agreement, setAgreement] = React.useState(true)
  const [loading, setLoading] = React.useState(false)
  const [subDivision, setSubDivision] = React.useState([])
  const [serviceRank, setServiceRank] = React.useState([])

  const [modalVisibility, setModalVisibility] = React.useState()
  const [othersCountryCode, setOthersCountryCode] = React.useState(false)
  const [industryData, setIndustryData] = React.useState([])
  const [interestedArea, setInterestedArea] = React.useState(
    props.showInterestedInValue !== "" ? props.showInterestedInValue : undefined
  )
  const [othersIndustry, setOthersIndustry] = React.useState(false)
  const [isFileUploading, setIsFileUploading] = React.useState(false)
  const [lastServingRank, setLastServingRank] = React.useState([])
  const [filename, setFilename] = React.useState("")
 

  React.useEffect(() => {
    props.form.validateFields()

    getLocationsAndIndustries();
    getVeteranData()
  }, [])
console.log("---location--->",location)
  const getLocationsAndIndustries = async () => {
    setLoading(true)

    await request("GET", "JobSite/IndustryType")
      .then(res => {
        if (res.data) {
          const indysData = res.data.map(data => ({
            industryCode: data.industryCode,
            industryDescription: data.industryDescription,
          }))
          setIndustryData(indysData)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
  }

  const changeInterestedArea = value => {
    setInterestedArea(value)
    props.getInterestedArea(value)
  }

  const handleCountry = value => {
    if (value === "others_country") {
      setOthersCountryCode(true)
    } else {
      setOthersCountryCode(false)
    }
    setCountry(value)
  }
  const handleIndustry = value => {
    if (value === "others_industry") {
      setOthersIndustry(true)
    } else {
      setOthersIndustry(false)
    }
  }

  const getBase64 = file => {
    let reader = new FileReader()
    return new Promise((resolve, reject) => {
      reader.readAsDataURL(file)
      reader.onload = function() {
        let result: any = reader.result
        result = result.split("base64")[1]
        resolve(result)
      }
    })
  }

  const onFileChange = async (data: any) => {
    setIsFileUploading(true)
    let files = data.target.files
    let filenameSplit = files[0].name.split(".")
    if (!validateFileType(filenameSplit[filenameSplit.length - 1])) {
      data.target.value = null
      setIsFileUploading(false)
      return
    }
    if (!validateFileSize(files[0])) {
      data.target.value = null
      setIsFileUploading(false)
      return
    }
    setIsFileUploading(true)
    let filename = `${uuid()}_cv.${filenameSplit[filenameSplit.length - 1]}`
    var bodyFormData = new FormData();
    const base64Format = await getBase64(files[0])
    bodyFormData.append('files', files[0]); 
    bodyFormData.append('applicantID',  Date.now()); 
    const headers :any= {
      "Access-Control-Allow-Origin": "*", 
      "Content-Type": "multipart/form-data",
    }
    Axios.post(
      `https://api.adecco.co.in/JobSite/VeteranFileUpload`,
      bodyFormData,
      headers
      )
      .then(res => {
        if(res.data){
          setFilename(res.data.fileName)  
          setIsFileUploading(false)
          message.success("File upload successfully!");
        }
      })
      .catch(err => {
        if (err?.data?.errors) {
          Object.keys(err.data.errors).forEach(key => {
            err.data.errors[key].forEach(error => {
              message.error(error)
            })
          })
        }

        message.error("Something went wrong!")
        setIsFileUploading(false)
      })
      // setFilename(filename)
    }
    
        // requestData("POST",'JobSite/VeteranFileUpload',bodyFormData,{"Access-Control-Allow-Origin": "*","Content-Type": "multipart/form-data"}).then(res=>{
        //   console.log("ress--->",res)
        // }).catch(err=>console.log(err))
    
  // const onChange = (e: any) => {
  //   let tempData = { [e.target.name]: e.target.value }
  //   setFormData(prevState => ({
  //     ...prevState,
  //     ...tempData,
  //   }))
  // }
  const onFinish = (e: any) => {
    e.preventDefault()
    

    props.form.validateFieldsAndScroll((err: any, values: any) => {
      if (!err) {
        setLoading(true)
        const {militaryExpYrs,militaryExpDays,militaryExpMonths,division_of_service,subdivision,current_status,service_type,date_of_retirement,educational_qualificatio,last_serving_rank}=values;
        // if (props.jobApply && props.jobApply.type === "JOB_APPLY") {
          const countryData = values.countryCode.split(":")
          const data = {
            appliedJobCode: props.jobApply.appliedJobCode,
            lastName: values.lastName,
            firstName: values.fistName,
            // CountryCode: countryData[0].substring(0, 3).toUpperCase(),
            mobile: countryData[1] + values.phoneNumber,
            eduQualification:educational_qualificatio,
            dob: values.dob.toISOString(),
            LocationArry:values.preferredLocation,
            gender:values.gender,
            LanguageArray:values.languageKnown.join(),
            addnCourse:values.additional_courses,
            itSkills:values.technical_it_skills ,
            militaryExpYrs:parseInt(militaryExpYrs),
            militaryExpMonths:parseInt(militaryExpMonths),
            militaryExpDays:parseInt(militaryExpDays),
            serviceDivisionSubCode:division_of_service,
            subDivisionCode:division_of_service,
            serviceRankCode:division_of_service,
            serviceRankSubCode:last_serving_rank,
            subDivisionSubCode:subdivision,
            serviceTypeSubCode:service_type,
            CurrentStatusSubCode:current_status,
            retirementDate:date_of_retirement,
            CreatedDate: new Date(),
            resumeFileName: filename,
            CreatedBy: "Adecco_Web_UAT",
          }
          console.log(data)
          requestData(
            "POST",
            "JobSite/VeteranApplicationSave",
            JSON.stringify({ ...data })
          )
            .then(res => {
              message.success("The form has been successfully submitted.")
              props.form.resetFields()
            })
            .catch(err => {
              if (err?.data?.errors) {
                Object.keys(err.data.errors).forEach(key => {
                  err.data.errors[key].forEach(error => {
                    message.error(error)
                  })
                })
              }

              message.error(
                "We encountered an error while submitting the form."
              )
            })
            .finally(() => setLoading(false))
       
      } else {
        message.error("We encountered an error while submitting the form.")
      }
    })
  }

  const handleModalView = () => {
    setModalVisibility(true)
  }

  const changeModalView = (value: boolean) => {
    setModalVisibility(value)
  }

  const checkCheckBox = (rule, value, callback) => {
    if (!value) {
      setAgreement(false)
      callback("Please agree the terms and conditions!")
    } else {
      setAgreement(true)
      callback()
    }
  }
  const handleDivision =async e => {
    console.log(e)
    await request("GET", `JobSite/VeteranMaster/SUBDIV/${e}`)
    .then(res => {
      if (res.data) {
        const suData = res.data.find(
          data => data.dataDescription === "Others"
        )
        const subDivData=res.data?.sort((a: any, b: any) => {
          if (a.dataDescription < b.dataDescription) return -1
          if (a.dataDescription > b.dataDescription) return 1
          return 0
        }).filter(data => data.dataDescription !== "Others")
        subDivData.push(suData)
        setSubDivision(subDivData?.map(sub=>({lable:sub.dataDescription,value:sub.subCode})))
      }
    })
    await request("GET", `JobSite/VeteranMaster/RANK/${e}`)
    .then(res => {
      if (res.data) {
        const suData = res.data.find(
          data => data.dataDescription === "Others"
        )
        const subDivData=res.data?.sort((a: any, b: any) => {
          if (a.dataDescription < b.dataDescription) return -1
          if (a.dataDescription > b.dataDescription) return 1
          return 0
        }).filter(data => data.dataDescription !== "Others")
        subDivData.push(suData)
        setServiceRank(subDivData?.map(sub=>({lable:sub.dataDescription,value:sub.subCode})))

        // setServiceRank(res.data.map(sub=>({lable:sub.dataDescription,value:sub.subCode}))?.sort((a: any, b: any) => {
        //   if (a.lable < b.lable) return -1
        //   if (a.lable > b.lable) return 1
        //   return 0
        // }))
      }
    })
  }
  const handleSubDivision =async e => {
    console.log(e)
    // await request("GET", `JobSite/VeteranMaster/RANK/${e}`)
    // .then(res => {
    //   if (res.data) {
    //     console.log("1111111---->",res.data)
        // setServiceRank(res.data.map(sub=>({lable:sub.dataDescription,value:sub.subCode}))?.sort((a: any, b: any) => {
        //   if (a.lable < b.lable) return -1
        //   if (a.lable > b.lable) return 1
        //   return 0
        // }))
      // }
    // })
  }
  

  const fistNameError = isFieldTouched("fistName") && getFieldError("fistName")
  const lastNameError = isFieldTouched("lastName") && getFieldError("lastName")
  const countryError =
    isFieldTouched("countryCode") && getFieldError("countryCode")
  const phoneNumberError =
    isFieldTouched("phoneNumber") && getFieldError("phoneNumber")
  const designationError =
    isFieldTouched("designation") && getFieldError("designation")
  const resumeError = isFieldTouched("resume") && getFieldError("resume")
  const dobError = isFieldTouched("dob") && getFieldError("dob")
  const genderError = isFieldTouched("gender") && getFieldError("gender")
  const languageKnownError =
    isFieldTouched("languageKnown") && getFieldError("languageKnown")
  const educationalQualificatioError =
    isFieldTouched("educational_qualificatio") &&
    getFieldError("educational_qualificatio")
  const additionalCoursesError =
    isFieldTouched("additional_courses") && getFieldError("additional_courses")
  const technicalItSkillsError =
    isFieldTouched("technical_it_skills") &&
    getFieldError("technical_it_skills")
  const totalMilitaryExperienceError =
    isFieldTouched("total_military_experience") &&
    getFieldError("total_military_experience")
  const divisioOfServiceError =
    isFieldTouched("division_of_service") &&
    getFieldError("division_of_service")
  const subdivisionError =
    isFieldTouched("subdivision") && getFieldError("subdivision")
  const lastServingRankError =
    isFieldTouched("last_serving_rank") && getFieldError("last_serving_rank")
  const serviceTypeError =
    isFieldTouched("service_type") && getFieldError("service_type")
  const currentStatusError =
    isFieldTouched("current_status") && getFieldError("current_status")
  const dateOfRetirementError =
    isFieldTouched("date_of_retirement") && getFieldError("date_of_retirement")

  const preferredLocationError =
    isFieldTouched("preferredLocation") && getFieldError("preferredLocation")
  const summaryError = isFieldTouched("summary") && getFieldError("summary")

  const militaryExpYrsError = isFieldTouched("militaryExpYrs") && getFieldError("militaryExpYrs");
  const militaryExpMonthsError = isFieldTouched("militaryExpMonths") && getFieldError("militaryExpMonths");
  const militaryExpDaysError = isFieldTouched("militaryExpDays") && getFieldError("militaryExpDays");

  return (
    <>
      <Form name="basic" onSubmit={onFinish} autoComplete="off">
        <Row type="flex">
          <Col span={24} lg={12}>
            <Form.Item
              className="mb-2 mr-0 mr-md-2"
              validateStatus={fistNameError ? "error" : ""}
              help={fistNameError || ""}
            >
              {getFieldDecorator("fistName", {
                validateTrigger: "onBlur",
                rules: [
                  {
                    required: true,
                    message: "Please enter your first name",
                  },
                  { max: 50, message: "Character limit exceeded" },
                  {
                    pattern: characterValidation,
                    message: "Please enter valid characters",
                  },
                ],
              })(
                <Input
                  name={"fistName"}
                  size="large"
                  placeholder={"First Name *"}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              className="mb-2 mr-0 mr-md-2"
              validateStatus={lastNameError ? "error" : ""}
              help={lastNameError || ""}
            >
              {getFieldDecorator("lastName", {
                rules: [
                  {
                    required: true,
                    message: "Please enter your last name",
                  },
                  { max: 50, message: "Character limit exceeded" },
                  {
                    pattern: characterValidation,
                    message: "Please enter valid characters",
                  },
                ],
              })(
                <Input size="large" name={"lastName"} placeholder="Last Name *" />
              )}
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              className="mb-2 mr-0 mr-md-2"
              name="countryCode"
              validateStatus={countryError ? "error" : ""}
              help={countryError || ""}
            >
              <ConfigProvider>
                {getFieldDecorator("countryCode", {
                  initialValue: "India:+91",
                  rules: [
                    {
                      required: true,
                      message: "Please select your country code",
                    },
                  ],
                })(
                  <Select
                    showSearch
                    placeholder="Select Country *"
                    optionFilterProp="children"
                    onChange={(value: string) => handleCountry(value)}
                    loading={loading}
                    filterOption={(input: any, option: any) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {countryCodeData
                      .sort((a: any, b: any) => {
                        if (a.country < b.country) return -1
                        if (a.country > b.country) return 1
                        return 0
                      })
                      ?.map((data, index) => {
                        return (
                          <Option
                            key={index}
                            value={data.country + ":" + data.countryCode}
                          >
                            {data.country + " : " + data.countryCode}
                          </Option>
                        )
                      })}
                    <Option value={"others_country"}>Others</Option>
                  </Select>
                )}
              </ConfigProvider>
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              label=""
              required
              className="mb-2 mr-0 mr-md-2"
              validateStatus={phoneNumberError ? "error" : ""}
              help={phoneNumberError || ""}
              // onChange={e => onChange(e)}
            >
              {getFieldDecorator("phoneNumber", {
                rules: [
                  {
                    required: true,
                    message: "Please enter your phone number",
                  },
                  {
                    pattern: phoneNumberValidation,
                    message: "Please enter valid digits!",
                  },
                  { min: 10, message: "Please enter valid digits!" },
                  { max: 10, message: "Please enter valid digits!" },
                  //    {
                  //      validator: (rule, value, callback) =>
                  //     checkMaxDigitLimit(rule, value, callback, country),
                  //    },
                ],
              })(<Input name={"phoneNumber"} placeholder="Phone Number *" />)}
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              className="mb-2 mr-0 mr-md-2"
              name="preferredLocation"
              validateStatus={preferredLocationError ? "error" : ""}
              help={preferredLocationError || ""}
            >
              {/* <ConfigProvider >
                            {getFieldDecorator('preferredLocation', {
                                validateTrigger: "onBlur",
                                rules: [
                                    {
                                        required: true,
                                        message: "Please Select Location",
                                    },
                                ],
                            })(
                                <Input size="large"  name={'preferredLocation'} placeholder="Preferred Location*" />,
                            )}
                        </ConfigProvider> */}
              <ConfigProvider>
                {getFieldDecorator("preferredLocation", {
                  validateTrigger: "onBlur",

                  rules: [
                    {
                      required: true,
                      message: "Please select your preferred location",
                    },
                  ],
                })(
                  <Select size="large" placeholder="Preferred Location *" >
                    {location?.sort((a: any, b: any) => {
                      if (a.lable < b.lable) return -1
                      if (a.lable > b.lable) return 1
                      return 0
                    })?.map(item => (
                      <Option key={item.value} value={item.value}>
                        {item.lable}
                      </Option>
                    ))}
                  </Select>
                )}
              </ConfigProvider>
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              className="mb-2 mr-0 mr-md-2"
              validateStatus={dobError?"error" : ""}
              help={dobError || ""}
              // setFieldsValue={dob}
            >
              <ConfigProvider>
                {getFieldDecorator("dob", {
                  // validateTrigger: "onBlur",

                  rules: [
                    {
                      required: true,
                      message: "Please select your dob",
                    },
                  ],
                  // rules: [{ type: 'object', required: true, message: 'Please select time!' }],
                })(
                  <DatePicker
                    style={{ width: "100%" }}
                    name={"dob"}
                    // onChange={e =>{console.log(e);setDob(e)}}
                    placeholder="Date of Birth *"
                    showTime format="DD-MM-YYYY"
                  />
                )}
              </ConfigProvider>
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              className="mb-2 mr-0 mr-md-2"
              name="gender"
              validateStatus={genderError ? "error" : ""}
              help={genderError || ""}
            >
              <ConfigProvider>
                {getFieldDecorator("gender", {
                  validateTrigger: "onBlur",

                  rules: [
                    {
                      required: true,
                      message: "Please select your gender",
                    },
                  ],
                })(
                  <Select size="large" placeholder="Gender *">
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                  </Select>
                )}
              </ConfigProvider>
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              className="mb-2 mr-0 mr-md-2"
              name="languageKnown"
              validateStatus={languageKnownError ? "error" : ""}
              help={languageKnownError || ""}
            >
              <ConfigProvider>
                {getFieldDecorator("languageKnown", {
                  validateTrigger: "onBlur",

                  rules: [
                    {
                      required: true,
                      message: "Please select your language known",
                    },
                  ],
                })(
                  <Select
                    mode="multiple"
                    size="large"
                    placeholder="Language Known *"
                  >
                    {language?.sort((a: any, b: any) => {
                      if (a.lable < b.lable) return -1
                      if (a.lable > b.lable) return 1
                      return 0
                    }).map(lag => (
                      <Option value={lag.value}>{lag.lable}</Option>
                    ))}
                  </Select>
                )}
              </ConfigProvider>
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              className="mb-2 mr-0 mr-md-2"
              validateStatus={educationalQualificatioError ? "error" : ""}
              help={educationalQualificatioError || ""}
            >
              {getFieldDecorator("educational_qualificatio", {
                validateTrigger: "onBlur",
                rules: [
                  {
                    required: true,
                    message: "Please enter your educational qualificatio",
                  },
                  // {
                  //   pattern: characterValidation,
                  //   message: "Please enter valid characters",
                  // },
                ],
              })(
                <Input
                  name={"educational_qualification"}
                  size="large"
                  placeholder={"Educational Qualification *"}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              className="mb-2 mr-0 mr-md-2"
              validateStatus={additionalCoursesError ? "error" : ""}
              help={additionalCoursesError || ""}
            >
              {getFieldDecorator("additional_courses", {
                validateTrigger: "onBlur",
                rules: [
                  // {
                  //   required: true,
                  //   message: "Please enter your additional_courses",
                  // },
                  // {
                  //   pattern: characterValidation,
                  //   message: "Please enter valid characters",
                  // },
                ],
              })(
                <Input
                  name={"additional_courses"}
                  size="large"
                  placeholder={"Additional Courses"}
                />
              )}
            </Form.Item>
          </Col>

          <Col span={24} lg={12}>
            <Form.Item
              className="mb-2 mr-0 mr-md-2"
              validateStatus={technicalItSkillsError ? "error" : ""}
              help={technicalItSkillsError || ""}
            >
              {getFieldDecorator("technical_it_skills", {
                validateTrigger: "onBlur",
                rules: [
                  // {
                  //   required: true,
                  //   message: "Please enter your technical / it skills",
                  // },
                  // {
                  //   pattern: characterValidation,
                  //   message: "Please enter valid characters",
                  // },
                ],
              })(
                <Input
                  name={"technical_it_skills"}
                  size="large"
                  placeholder={"Technical / IT Skills "}
                />
              )}
            </Form.Item>
          </Col>
          </Row>
          <Row>
          <div className="mb-3 c-secondary">Total Military Experience(Years/Months/Days) * </div>
          <Col span={4} lg={8}>
            <Form.Item
              className="mb-2 mr-0 mr-md-2"
              validateStatus={militaryExpYrsError ? "error" : ""}
              help={militaryExpYrsError || ""}
            >
              {getFieldDecorator("militaryExpYrs", {
                validateTrigger: "onBlur",
                rules: [
                  {
                    pattern:numberVal,
                    required: true,
                    message: "Please enter your total military experience in Years",
                  }
                ],
              })(
                <Input
                  name={"militaryExpYrs"}
                  size="large"
                  placeholder={"Years"}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={4} lg={8}>
            <Form.Item
              className="mb-2 mr-0 mr-md-2"
              validateStatus={militaryExpMonthsError ? "error" : ""}
              help={militaryExpMonthsError || ""}
            >
              {getFieldDecorator("militaryExpMonths", {
                validateTrigger: "onBlur",
                rules: [
                  {
                    pattern:numberVal,
                    required: true,
                    message: "Please enter your total military experience in Month",
                  }
                ],
              })(
                <Input
                  name={"militaryExpMonths"}
                  size="large"
                  placeholder={"Months"}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={4} lg={8}>
            <Form.Item
              className="mb-2 mr-0 mr-md-2"

              validateStatus={militaryExpDaysError ? "error" : ""}
              help={militaryExpDaysError || ""}
            >
              {getFieldDecorator("militaryExpDays", {
                validateTrigger: "onBlur",
                rules: [
                  {
                    pattern:numberVal,
                    required: true,
                    message: "Please enter your total military experience in Days",
                  },
                ],
              })(
                <Input
                  name={"militaryExpDays"}
                  size="large"
                  placeholder={"Days"}
                />
              )}
            </Form.Item>
          </Col>
          </Row>
          <Row type="flex">
          <Col span={24} lg={12}>
            <Form.Item
              className="mb-2 mr-0 mr-md-2"
              name="division_of_service"
              validateStatus={divisioOfServiceError ? "error" : ""}
              help={divisioOfServiceError || ""}
            >
              <ConfigProvider>
                {getFieldDecorator("division_of_service", {
                  validateTrigger: "onBlur",

                  rules: [
                    {
                      required: true,
                      message: "Please select your division of service",
                    },
                  ],
                })(
                  <Select
                    size="large"
                    placeholder="Division of Service *"
                    onChange={e => handleDivision(e)}
                  >
                    {division?.map(data => (
                      <Option value={data.value}>{data.lable}</Option>
                    ))}
                  </Select>
                )}
              </ConfigProvider>
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              className="mb-2 mr-0 mr-md-2"
              name="subdivision"
              validateStatus={subdivisionError ? "error" : ""}
              help={subdivisionError || ""}
            >
              <ConfigProvider>
                {getFieldDecorator("subdivision", {
                  validateTrigger: "onBlur",

                  rules: [
                    {
                      required: true,
                      message: "Please select your Sub Division of service",
                    },
                  ],
                })(
                  <Select size="large" placeholder="Subdivision *" onChange={e => handleSubDivision(e)}>
                    {subDivision?.map(data => (
                      <Option value={data.value}>{data.lable}</Option>
                    ))}
                  </Select>
                )}
              </ConfigProvider>
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              className="mb-2 mr-0 mr-md-2"
              name="last_serving_rank"
              validateStatus={lastServingRankError ? "error" : ""}
              help={lastServingRankError || ""}
            >
              <ConfigProvider>
                {getFieldDecorator("last_serving_rank", {
                  validateTrigger: "onBlur",

                  rules: [
                    {
                      required: true,
                      message: "Please select your last serving rank",
                    },
                  ],
                })(
                  <Select size="large" placeholder="Last Serving Rank *">
                    {serviceRank?.map(data => (
                      <Option value={data.value}>{data.lable}</Option>
                    ))}
                  </Select>
                )}
              </ConfigProvider>
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              className="mb-2 mr-0 mr-md-2"
              name="service_type"
              validateStatus={serviceTypeError ? "error" : ""}
              help={serviceTypeError || ""}
            >
              <ConfigProvider>
                {getFieldDecorator("service_type", {
                  validateTrigger: "onBlur",

                  rules: [
                    {
                      required: true,
                      message: "Please select your service type",
                    },
                  ],
                })(
                  <Select size="large" placeholder="Service Type *">
                    <Option value="PC">
                      Permanent Commision
                    </Option>
                    <Option value="SSC">
                      Short Service Commision
                    </Option>
                  </Select>
                )}
              </ConfigProvider>
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              className="mb-2 mr-0 mr-md-2"
              name="current_status"
              validateStatus={currentStatusError ? "error" : ""}
              help={currentStatusError || ""}
            >
              <ConfigProvider>
                {getFieldDecorator("current_status", {
                  validateTrigger: "onBlur",

                  rules: [
                    {
                      required: true,
                      message: "Please select your current status",
                    },
                  ],
                })(
                  <Select size="large" placeholder="Current Status *">
                    <Option value="RwithCE">
                      Retired with Corporate Experience
                    </Option>
                    <Option value="RwithoutCE">
                      {" "}
                      Retired without Corporate Experience
                    </Option>
                  </Select>
                )}
              </ConfigProvider>
            </Form.Item>
          </Col>
          <Col span={24} lg={12}>
            <Form.Item
              className="mb-2 mr-0 mr-md-2"
              validateStatus={dateOfRetirementError ? "error" : ""}
              help={dateOfRetirementError || ""}
            >
              <ConfigProvider>
                {getFieldDecorator("date_of_retirement", {
                  // validateTrigger: "onBlur",

                  rules: [
                    {
                      required: true,
                      message: "Please select your date of retirement",
                    },
                  ],
                })(
                  <DatePicker
                    style={{ width: "100%" }}
                    name={"date_of_retirement"}
                    placeholder="Date Of Retirement *"
                    showTime format="DD-MM-YYYY"  
                  />
                )}
              </ConfigProvider>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <div className="mb-3 c-secondary">Attach Resume *</div>
          <Col span={24} lg={12}>
            <Form.Item
              className="mb-2 mr-0 mr-md-2"
              name="resume"
              validateStatus={resumeError ? "error" : ""}
              help={resumeError || ""}
            >
              {getFieldDecorator("resume", {
                rules: [
                  {
                    required: false,
                    message: "Please upload resume!",
                  },
                ],
              })(
                <Input
                  type="file"
                  onChange={onFileChange}
                  accept=".pdf,.doc,.docx"
                  size="large"
                />
              )}
            </Form.Item>
            <Row className="text-left mr-1">
              <small className="c-secondary text-left">
                Supported Formats: doc,docx,pdf,rtf Max file size: 5 MB
              </small>
            </Row>
          </Col>

          {/* <Col span={24} lg={12}>
                    <Form.Item className="mb-2 mr-0 mr-md-2">
                        {getFieldDecorator("linkdin_profile", {
                            validateTrigger: "onBlur",
                            rules: [
                                {
                                    required: false,
                                    // message: "Please enter your preferredLocation!",
                                },
                                { max: 250, message: "Character limit exceeded" },
                                // {
                                //   validator: (rule, value, callback) =>
                                //     checkRequiredValue(rule, value, callback, "preferredLocation"),
                                // },
                            ],
                        })(<Input size="large" placeholder="Linkedin Profile (optional)" prefix={<Icon type="linkedin" theme="filled" style={{ color: '#0e76a8' }} />} />)}
                         
                    </Form.Item>
                    <Row className='text-left ml-1'>
                        <small className="c-secondary text-left">
                            Note: paste your linkedin profile link here
                        </small>
                    </Row>
                   
                </Col> */}
        </Row>
        <Row>
          <Form.Item
            className=""
            name="remember"
            valuePropName="checked"
            wrapperCol={{ span: 20 }}
          >
            {getFieldDecorator("agreement", {
              valuePropName: "checked",
              initialValue: "true",
              rules: [{ validator: checkCheckBox }],
            })(
              <div>
                <Checkbox className="c-secondary pr-2" defaultChecked={true}>
                  I agree to the{" "}
                </Checkbox>
                <a>
                  <small className="c-info" onClick={handleModalView}>
                    Terms and conditions
                  </small>
                </a>
                {modalVisibility && (
                  <ModalComponent
                    title="Terms and condition"
                    isVisible={modalVisibility as boolean}
                    getValueModal={changeModalView}
                    modalData={TermsConditionData()}
                  />
                )}
              </div>
            )}
          </Form.Item>
        </Row>
        <Form.Item className="mediaFormButton" wrapperCol={{ span: 16 }}>
          {loading ? (
            <Button className="text-center" loading={loading} disabled={true}>
              {props.btnName ? props.btnName : "Submit"}
            </Button>
          ) : (
            <Button
              className="text-center"
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError())}
            >
              {props.btnName ? props.btnName : "Submit"}
            </Button>
          )}
        </Form.Item>
      </Form>
    </>
  )
}

export const WrappedVeteranSubmissionForm = Form.create({
  name: "veteran-submission",
})(VeteranSubmissionForm)
