import React, { Fragment } from "react";
import { Row, Col, Breadcrumb, Card, Divider } from "antd";
import { Link } from "gatsby";
import { PressReleaseData } from "../../../../services/PressReleaseData";
import  {
  PageContentEmployerScreen,
} from "../../../components/PageContent/PageContent";
import banner_img1 from "../../../images/PressRelease/ADECCOGROUPRANKED7THBESTWORKPLACE.webp";
import backIcon from "../../../images/icons/icons8-back-24.webp";
import fbIcon from "../../../images/icons/facebook.webp";
import ldIcon from "../../../images/icons/linkedin.webp";
import ttIcon from "../../../images/icons/twitter.webp";
import "./common.less";

export default function PressRelease(props) {
  const data={
    press_id:"PRESS005",
    id: "the-adecco-group-ranked-7th-best-workplace-in-the-world",
    banner_img: banner_img1,
    category: "General News",
    date: "Zürich, Switzerland, Oct 13, 2020",
    title: "The Adecco Group ranked 7th Best Workplace in the World",
    description:
      "A strong corporate culture, employees’ empowerment and team spirit make the Adecco Group a top employer",
    content: `
    <h1>THE ADECCO GROUP RANKED 7TH BEST WORKPLACE IN THE WORLD</h1>
<h4 class="text-align-center"><b>A strong corporate culture, employees’ empowerment and team spirit make the Adecco Group a top employer</b></h4>
<p class="c-secondary text-align-center">Zürich, Switzerland, Oct 13, 2020</p>
<p>The Adecco Group was ranked seventh best workplace in the world among thousands of companies, according to the Great Place to Work® survey, reinforcing the HR-solutions provider’s reputation as one of the most attractive and rewarding global employers.</p>
<p>For the fifth year running, the Adecco Group has been featured in the top 25 world’s best workplaces, according to the Great Place to Work® survey, which polled ten million employees from more than 10,000 companies in 92 countries. The Zurich-based company was named eleventh best global workplace in 2019.</p>
<p>Commenting on the announcement, Adecco Group Chief Executive Officer Alain Dehaze said: “As a leader in the world of work, we want to set an example in how we create a positive and inspiring environment to attract, retain and empower our people across the world. We are delighted that this ongoing commitment has once again been recognised in the global Great Place to Work ranking, especially given the disruption and uncertainty caused by the Covid-19 pandemic this year. First and foremost, the Adecco Group is a human-centric company, built on a diverse and talented group of people who work towards a shared purpose: to make the future work for everyone.”</p>
<p>According to the Great Place to Work® survey, the Adecco Group’s employees feel they work for a company with a strong culture of team spirit, pride and empowerment. Employees highlighted a welcoming, friendly and nurturing work environment where they can be themselves and are treated equally. They are proud of their accomplishments and feel like they are given the responsibility to make decisions in their jobs, including being able to tailor their work schedules.</p>
<p>Thirteen Adecco Group subsidiaries around the world were ranked nationally and contributed to the global ranking. Those countries were Belgium, Canada, Denmark, Germany, Greater China, Greece, Ireland, Italy, Netherlands, Poland, Singapore, Spain, and the United Kingdom.</p>
<p>More information is available on the <a href="https://www.greatplacetowork.com/best-workplaces-international/world-s-best-workplaces/2020">Great Place to Work® website.</a></p>
<h4><b>For further information please contact:</b></h4>
  <p>Cauvery Uthappa | <a href="mailto:cauvery.uthappa@adecco.com" class="taxt-dark">cauvery.uthappa@adecco.com</a> | <a href="tel:+919845172958" class="taxt-dark">+919845172958</a></p>
    `,
    link:
      "/employer/press-release/press-release-5",
  }

  

  const listData: any = []
  for (let i = 0; i < 6; i++) {
    listData.push({
      href: "https://ant.design",
      title: `ant design part ${i}`,
      avatar: "https://joeschmoe.io/api/v1/random",
      description:
        "Ant Design, a design language for background applications, is refined by Ant UED Team.",
      content:
        "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
    })
  }
  return (
    <>
     

      <PageContentEmployerScreen
        location={props.location}
      >
         {data?.banner_img && (
        <section className="PressSection">
          <Row>
            <div className="hero-section" style={{backgroundImage: `url('${data.banner_img}')`}}>
              <div className="overlay"></div>
              <div className="content-section"></div>
            </div>
          </Row>
        </section>
      )}
        <Row className="custome-breadcrumb mt-5">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/employer/press-release"}>
                  <img loading="lazy"  src={backIcon} /> Back to Press Release
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="float-right">
            <div className="d-flex">
              <span>Share|</span>
              <a href="" className="mx-1">
                {" "}
                <img loading="lazy"  src={fbIcon} alt="Facebook" />
              </a>
              <a href="" className="mx-1">
                {" "}
                <img loading="lazy"  src={ldIcon} alt="linkedin" />
              </a>
              <a href="" className="mx-1">
                {" "}
                <img loading="lazy"  src={ttIcon} alt="twitter" />
              </a>
            </div>
          </Col>
        </Row>
        <Row type="flex" className="mobile-flex">
          <Col lg={9} md={24} className="px-3 related-press-release-card">
            <Card title="Related Press Release" size="small" className="">
              {PressReleaseData.map(
                (data, i) =>
                  i > 4 && (
                    <Fragment key={data.id}>
                      <div className="d-flex">
                        <img loading="lazy" 
                          src={data.releted_img}
                          alt="img"
                          className="rounded"
                          width={100}
                        />
                        <Link
                          to={data.link}
                          className="ml-2 fw-600 press-title"
                        >
                          {data.title}
                        </Link>
                      </div>
                      <Divider />
                    </Fragment>
                  )
              )}
            </Card>
          </Col>
          <Col lg={14} md={24} className="press-release-section">
            {data && (
              <Row
                className="fw-500 font-normal"
                dangerouslySetInnerHTML={{ __html: data?.content }}
              ></Row>
            )}
          </Col>
        </Row>
      </PageContentEmployerScreen>
    </>
  )
}
