import React, { Fragment, useEffect, useState } from "react"
import { Link } from "gatsby"
import moment from "moment"
import { Row, Col, Collapse, Icon, Breadcrumb, Skeleton, Empty } from "antd"
import { Select, Card, Checkbox, DatePicker, Input, Pagination } from "antd"
// import { jobSeeker } from "../../../services/jobSeekerData";
import { staticJobs } from "../../../services/jobSeekerData"

import PageContent from "../../components/PageContent/PageContent"
import { PressReleaseData } from "../../../services/PressReleaseData"
import LocationIcon from "../../images/location.svg"
import Baner from "../../images/Job-Seekers.webp"
import { jobByData } from "../../../services/jobSekeerHomePageData"
import RenderMenu from "../../components/Menu/RenderMenu"
import { searchFJobData } from "../../../services/HeaderData"
import { request, ScrollToTop } from "../../helper/dimensionHelper"
import { APIContext } from "../../helper/APIContex"

const { RangePicker } = DatePicker
const { Option } = Select

const cardSize = 6
const JobSeeker = props => {
  const { data, actions } = React.useContext(APIContext)
  const { jobspageLocationData, jobspageIndstryData, jobsbyTypeData } = data
  console.log("-----State-->>", props.location.state)
  function getQueryVariable() {
    const isBrowser = typeof window !== "undefined"
    const query = isBrowser?window?.location?.search?.substring(1):null
    const vars = query?query?.split("&")?.map(q => q.split("=").pop()):[]
    return vars
  }

  const dateFormat = "YYYY/MM/DD"
  const [value, setValue] = React.useState(1)
  const [dateRange, setDateRange] = React.useState([moment(), moment()])
  const [totalPage, setTotalPage] = React.useState(
    PressReleaseData.length / cardSize
  )
  const [current, setCurrent] = React.useState(1)
  const [minIndex, setMinIndex] = React.useState(0)
  const [maxIndex, setMaxIndex] = React.useState(cardSize)
  const [search, setSearch] = React.useState("")
  const [jobsData, setJobsData] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [industryFilter, setIndustryFilter] = useState([])
  const [locationFilter, setLocationFilter] = useState([])
  const [typeFilter, setTypeFilter] = useState([])

  useEffect(() => setLoading(data.loading), [data.loading])

  useEffect(() => {
    callAPi()
  }, [])
  const callAPi = async () => {
    const prams = getQueryVariable()
    await getJobsData(prams)
  }
  const getJobsData = async prams => {
    if (prams && prams[0] && prams[1]) {
      setLoading(true)
      await request("GET", `JobSite/${prams[0]}/${prams[1]}`)
        .then(res => {
          if (res?.data) setJobsData(res?.data)
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false))
    } else {
      setLoading(true)
      await request("GET", "JobSite/Jobs/NULL/NULL/NULL/NULL")
        .then(res => {
          if (res?.data) setJobsData(res?.data)
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false))
    }
  }
  const handleFilterAPI = async (type, value) => {
    setLoading(true)
    if (type === "JobByIndustry") {
      await request("GET", `JobSite/Jobs/NULL/NULL/${value}/NULL`)
        .then(res => {
          if (res?.data) setJobsData(res?.data)
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false))
    }
    if (type === "JobByLocation") {
      await request("GET", `JobSite/Jobs/NULL/NULL/NULL/${value}`)
        .then(res => {
          if (res?.data) setJobsData(res?.data)
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false))
    }
  }

  const onChange = (type, value) => {
    console.log("-----value-->", value)
    switch (type) {
      case "JobByIndustry":
        setIndustryFilter(value)
        // handleFilterAPI(type,value)
        break
      case "JobByLocation":
        // handleFilterAPI(type,value)

        setLocationFilter(value)
        break
      case "JobByType":
        setTypeFilter(value)
        break

      default:
        null
    }
    setValue(value)
  }

  const { Panel } = Collapse
  const { Search } = Input
  function callback(key) {
    console.log(key)
  }
  const handleChangePagination = page => {
    setCurrent(page)
    setMinIndex((page - 1) * cardSize)
    setMaxIndex(page * cardSize)
    ScrollToTop();

  }

  function onBlur() {
    console.log("blur")
  }

  function onFocus() {
    console.log("focus")
  }

  function onSearch(val) {
    console.log("search:", val)
  }
  const searchFilterData = jobsData.filter(data =>
    search && search.length > 0
      ? data?.jobTitle?.toLowerCase()?.includes(search?.toLocaleLowerCase())
      : data
  )
  const industryFilterData = searchFilterData.filter(data =>
    industryFilter && industryFilter.length > 0
      ? industryFilter.includes(data.industryType)
      : data
  )
  const locationFilterData = industryFilterData.filter(data =>
    locationFilter && locationFilter.length > 0
      ? locationFilter.includes(data.jobLocation)
      : data
  )
  const jobTypeFilterData = !typeFilter.includes("ALL")
    ? locationFilterData.filter(data =>
        typeFilter && typeFilter.length > 0
          ? typeFilter.includes(data.jobType)
          : data
      )
    : locationFilterData

  const filterData = jobTypeFilterData
  // const filterData = jobSeeker?.jobsData.filter(fil=>{
  //   if(search && search.length>0){
  //     return fil.jobTitle.toLowerCase().includes(search.toLocaleLowerCase())
  //   }else{
  //     return fil
  //   }
  // })

  return (
    <>
      <Row>
        <div className="hero-section" style={{ background: `url(${Baner})` }}>
          <div className="overlay"></div>
          <div className="content-section">
            <h1>Job Seeker</h1>
          </div>
        </div>
      </Row>

      <PageContent location={props.location} pageSize={24}>
        <Row className="custome-breadcrumb m-5">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to={"/"}>Home</Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item overlay={<RenderMenu data={searchFJobData} />}>
                Search for job
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a href="">View All Job</a>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row type="flex" justify="space-between" className=" m-5 job-seeker">
          <Col xs={24} xl={6} lg={6} md={10} sm={10} className="category-card">
            <Card title="Browse by Category">
              <Collapse defaultActiveKey={["1"]} onChange={callback}>
                <Panel header=" Jobs by Industry" key="1">
                  <div className="job-category1">
                    {/* {jobspageIndstryData?.sort((a:any,b:any)=>{if(a.industryDescription < b.industryDescription) return -1; if(a.industryDescription > b.industryDescription) return 1; return 0})?.map(data=>
                    <Checkbox key={data.industryCode} className="chek-option" onChange={(e)=>onChange("JobByIndustry",e)}>{data.industryDescription}</Checkbox>
                      )}
                      <Checkbox className="chek-option" onChange={(e)=>onChange("JobByIndustry",e.target.value)}>Others</Checkbox> */}
                    {jobspageIndstryData ? (
                      loading ? (
                        <Skeleton
                          paragraph={{
                            rows: 15,
                          }}
                        />
                      ) : (
                        <Checkbox.Group
                          style={{ display: "contents" }}
                          options={jobspageIndstryData.sort(
                            (a: any, b: any) => {
                              if (a.label < b.label) return -1
                              if (a.label > b.label) return 1
                              return 0
                            }
                          )}
                          onChange={val => onChange("JobByIndustry", val)}
                        />
                      )
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                  </div>
                </Panel>
                <Panel header="Jobs by City" key="2">
                  <div className="job-category2">
                    {/* {jobspageLocationData?.sort((a:any, b:any)=>{if(a.locationDescription < b.locationDescription) return -1; if(a.locationDescription > b.locationDescription) return 1; return 0}).map(d =>
                      <Checkbox key={d.loctionCode} className="chek-option" onChange={(e)=>onChange("JobByLocation",e)}>{d.locationDescription}</Checkbox>
                    )} */}
                    {jobspageIndstryData ? (
                      loading ? (
                        <Skeleton
                          paragraph={{
                            rows: 15,
                          }}
                        />
                      ) : (
                        <Checkbox.Group
                          style={{ display: "contents" }}
                          options={jobspageLocationData.sort(
                            (a: any, b: any) => {
                              if (a.label < b.label) return -1
                              if (a.label > b.label) return 1
                              return 0
                            }
                          )}
                          onChange={val => onChange("JobByLocation", val)}
                        />
                      )
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                    {/* <Checkbox className="chek-option" onChange={(e)=>onChange("JobByLocation",e.target.value)}>Others</Checkbox> */}
                  </div>
                </Panel>
                <Panel header="Work Type" key="3">
                  <div className="job-category3">
                    {/* jobsbyTypeData */}
                    {jobspageIndstryData ? (
                      loading ? (
                        <Skeleton
                          paragraph={{
                            rows: 15,
                          }}
                        />
                      ) : (
                        <Checkbox.Group
                          style={{ display: "contents" }}
                          options={jobsbyTypeData.sort((a: any, b: any) => {
                            if (a.label < b.label) return -1
                            if (a.label > b.label) return 1
                            return 0
                          })}
                          onChange={val => onChange("JobByType", val)}
                        />
                      )
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                    {/* 
                    <Checkbox className="chek-option" onChange={onChange}>All</Checkbox>
                    <Checkbox className="chek-option" onChange={onChange}>Permanent</Checkbox>
                    <Checkbox className="chek-option" onChange={onChange}>Temporary</Checkbox>
                    <Checkbox className="chek-option" onChange={onChange}>Freelancer</Checkbox> */}
                  </div>
                </Panel>
              </Collapse>
            </Card>
          </Col>
          <Col xs={24} xl={17} lg={17} md={13} sm={13}>
            <Row className="mb-3">
              <Col xs={24} xl={12} lg={12} md={14} sm={24}>
                <Search
                  placeholder="search by job"
                  onChange={e => setSearch(e.target.value)}
                  onSearch={value => console.log(value)}
                />
              </Col>
              {/* <Col xs={24} xl={12} lg={12} md={10} sm={24} className="text-end">
                <Select
                  showSearch
                  // style={{ width: 200 }}
                  className="catagorySort"
                  placeholder="Sort by"
                  optionFilterProp="children"
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="recent">Recent</Option>
                  <Option value="oldest">Oldest</Option>
                  <Option value="A_Z">A-Z</Option>
                  <Option value="Z_A">Z-A</Option>
                </Select>
              </Col> */}
            </Row>
            <Row gutter={[16, 24]} className="job-section">
              {filterData && filterData.length > 0 ? (
                loading ? (
                  <>
                    <Col xs={24} xl={12} lg={12} md={24} sm={24}>
                      <Card>
                        <Skeleton
                          paragraph={{
                            rows: 5,
                          }}
                        />
                      </Card>
                    </Col>
                    <Col xs={24} xl={12} lg={12} md={24} sm={24}>
                      <Card>
                        <Skeleton
                          paragraph={{
                            rows: 5,
                          }}
                        />
                      </Card>
                    </Col>
                  </>
                ) : (
                  filterData?.map(
                    (data, index) =>
                      parseInt(index) >= parseInt(minIndex) &&
                      parseInt(index) < parseInt(maxIndex) && (
                        <Col
                          xs={24}
                          xl={12}
                          lg={12}
                          md={24}
                          sm={24}
                          key={data.jobCode}
                        >
                          <div
                            className="job-card-ribbon"
                            data-label={data.jobTypeDescription}
                          >
                            <Card
                              bordered={false}
                              style={{ height: "100%", width: "100%" }}
                              actions={[
                                <p>
                                  <Icon type="calendar" key="calendar" />{" "}
                                  {moment(new Date(data.targetDate)).format(
                                    "MMM DD YYYY"
                                  )}
                                </p>,
                                <Link
                                  to={`/jobs-data/details/${data.jobCode}`}
                                >
                                  See job description{" "}
                                  <Icon type="arrow-right" key="arrow-right" />{" "}
                                </Link>,
                              ]}
                            >
                              <h3 className="job-title">{data.jobTitle}</h3>
                              {/* <br />
                    <br />
                    <br /> */}
                              <p
                                style={{ height: "83px" }}
                                dangerouslySetInnerHTML={{
                                  __html: data.jobShortDesc,
                                }}
                              ></p>
                              {/* <ul>
                      {data?.jobDesc.map((desc,index)=>
                      <li key={index}>
                      <p>
                        {desc}
                      </p>
                    </li>   
                        )}
                    </ul> */}
                              <div className="job-location">
                                <img loading="lazy"  src={LocationIcon} alt="Location" />
                                <p>{data.locationDescription}</p>
                              </div>
                            </Card>
                          </div>
                        </Col>
                      )
                  )
                )
              ) : (
                <div style={{ padding: "100px 0px" }}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </div>
              )}
            </Row>
          </Col>
        </Row>
        <Row type="flex" justify="center" className="mt-3">
          <Pagination
            pageSize={cardSize}
            current={current}
            total={filterData.length}
            onChange={e => handleChangePagination(e)}
          />
        </Row>
      </PageContent>
    </>
  )
}

export default JobSeeker
